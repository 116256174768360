/**
 * Inventory source types
 */
export const InventorySource = Object.freeze({
    STAAR: 1,
    CONSIGNMENT: 2,
    LOCAL: 3,
});

export const ConsignmentLensStatuses = Object.freeze({
    UNKNOWN: 0,
    AVAILABLE: 1,
    RESERVED: 2,
    CONSUMED: 3,
    SHIPPED: 4,
});

export const LENS_ACTIONS = Object.freeze({
    ADD_TO_CART: 'addtocart',
    CANCEL_RESERVATION: 'cancel',
    CONSUME: 'consume',
    SHIP: 'ship',
});
